<template>
  <onboard-wrapper title="Welcome to Truya">
    <validation-observer ref="authenticationObserver" style="width: 100%">
      <validation-provider
        #default="{ errors }"
        name="email"
        rules="required|email"
      >
        <v-text-field
          style="width: 100%"
          v-model="userEmail"
          :error-messages="errors"
          label="Email"
          outlined
          block
        ></v-text-field>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="password"
        rules="required"
      >
        <v-text-field
          type="password"
          style="width: 100%"
          v-model="password"
          :error-messages="errors"
          label="Password"
          outlined
        ></v-text-field>
      </validation-provider>
      <v-btn
        class="mt-3"
        block
        color="primary"
        to="/login"
        elevation="5"
        :loading="loading"
        large
        @click="login()"
      >
        Login
      </v-btn>
    </validation-observer>
  </onboard-wrapper>
</template>
<script>
import { mapState } from "vuex";
import firebase from "firebase/app";
import OnboardWrapper from "./OnboardWrapper.vue";
import "firebase/auth";
import "firebase/firestore";
export default {
  components: {
    OnboardWrapper,
  },
  data() {
    return {
      userEmail: "",
      password: "",
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
      currentOrganization: (state) => state.auth.currentOrganization,
    }),
  },
  methods: {
    async login() {
      try {
        const valid = await this.$refs.authenticationObserver.validate();
        if (valid) {
          console.log("login");
          this.loading = true;
          this.errorMessage = "";
          const user = await firebase
            .auth()
            .signInWithEmailAndPassword(this.userEmail, this.password);

          if (user != null) {
            await this.$store.dispatch("auth/fetchCurrentUser", user.user.uid);
            console.log(this.currentUser);
            if (this.currentUser.admin) {
              await this.$store.dispatch("auth/fetchCurrentOrganization");
              console.log(this.currentOrganization);
              if (this.currentOrganization.active) {
                if (!this.currentOrganization.setup) {
                  console.log("Go to settings");
                  this.$router.push("/onboard/settings");
                } else if (this.currentOrganization.payment_setup) {
                  this.$router.push("/");
                } else {
                  this.$router.push("/onboard/payment");
                }
              } else {
                await firebase.auth().signOut();
                this.$toast.error("Organization inactive");
              }
            } else {
              await firebase.auth().signOut();
              this.$toast.error("Only admins can login");
            }
          }
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        if (firebase.auth().currentUser) {
          await firebase.auth().signOut();
        }
        this.errorMessage = error.message ? error.message : "Failed to login";
        this.$toast.error(this.errorMessage);
      }
    },
  },
};
</script>
